/* eslint-disable import/order */
import { Menu as MenuAnt, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { auth } from '../../utils/firebase';
import { useAuthState } from '../../utils/hook';
import { PageName, Path as subPath } from '../../utils/subdomain';
import { pathNameNoPermission } from './Constant';
import { AsideResponsive } from './MenuStyle';

const Menu = () => {
  const { user, initializing } = useAuthState(auth);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [openSubMenu, setOpenSubMenu] = useState([]);
  const [menu, setMenu] = useState([])
  const hiddenSub = useRef(null);
  const history = useHistory();
  const menu_user = JSON.parse(localStorage.getItem('menu_user'));
  const [hidden, setHidden] = useState(false)
  let firstPath = '/' + window.location.pathname.split('/')[1];

  useEffect(() => {
    if (window.location.pathname === '/') {
      /** redirecting to first path menu */
      history.push(menu_user[0].path);
    }

    let hidden = menu_user

    hidden.forEach((val) => {
      if (val.head === 'SecondHand') {
        let filteredHiddenMenu = val.menu.filter((e) => e.menu_code !== 'M11003')
        hiddenSub.current = filteredHiddenMenu
      }
    })

    menu_user.forEach((val, i) => {
      // setSelectedMenu([i]);
      if (val.head === 'SecondHand') {
        val.menu = hiddenSub.current
      }
      if (val.menu) {
        val.menu.forEach((el, k) => {
          if (el.path === window.location.pathname) {
            setSelectedMenu([`sub_${i}_${k}`]);
            setOpenSubMenu([`head_${i}`]);
          }
        });
      }
    });

    const menuHidden = menu_user

    menuHidden.forEach((val, i) => {
      // setSelectedMenu([i]);
      if (val.head === 'SecondHand') {
        val.menu = hiddenSub.current
      }
      if (val.menu) {
        val.menu.forEach((el, k) => {
          if (el.path === window.location.pathname) {
            setSelectedMenu([`sub_${i}_${k}`]);
            setOpenSubMenu([`head_${i}`]);
          }
        });
      }
    });
    setMenu(menuHidden)
  }, []);

  useEffect(() => {
    menu_user.forEach((val, i) => {
      // setSelectedMenu([i]);
      if (val.menu) {
        val.menu.forEach((el, k) => {
          if (window.location.pathname === '/sign-second-hand') {
            setHidden(true);
          }
        });
      }
    });
  }, [window.location.pathname]);

  const checkPermission = () => {
    let isPermission = false;
    let firstPath = '/' + window.location.pathname.split('/')[1];

    menu_user.forEach((el) => {
      let index = [...el.menu, ...pathNameNoPermission].findIndex((val) => val.path == firstPath);
      if (index != -1) {
        isPermission = true;
      }
    });

    if (!isPermission && !initializing && user) {
      if (window.location.pathname !== '/dashboard') {
        let menuNotAllowed = PageName.filter((el) => el.path === firstPath);
        Modal.warning({
          title: `ผู้ใช้ ${user.email} ไม่มีสิทธิ์เข้าใช้งานเมนู ${(menuNotAllowed && menuNotAllowed[0].name) || firstPath}`,
          content: 'หากต้องการเข้าใช้งาน กรุณาติดต่อฝ่ายซัพพอท(เฟิร์น) skype: dappmaker08@gmail.com',
        });

      }
      history.push({ pathname: menu_user[0].menu[0].path || '/' });
    }
  };

  checkPermission();

  const handleClickMenu = (e) => {
    if (e.keyPath.length === 1) {
      setOpenSubMenu([]);
    }
    setSelectedMenu(e.key);
  };

  const onOpenChange = (e) => {
    setOpenSubMenu(e);
  };

  return (
    <div>
      {!hidden ? (
        <AsideResponsive className="main-sidebar sidebar-dark-primary elevation-4">
          <Link to={subPath.Dashboard} className="brand-link">
            <img
              src="/dist/img/app-logo.png"
              alt="Ufriend Logo"
              className="brand-image img-circle elevation-3"
              style={{ backgroundColor: '#0052ba' }}
            />
            <span className="brand-text font-weight-light">Ufriend Admin</span>
            <br />
            <p style={{ color: '#fff', fontSize: '12px', textAlign: 'center' }}>Version 1.0.3 12/02/2024 17:24</p>
          </Link>
          <div className="sidebar">
            {!initializing && user && (
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img src={user.photoURL} className="img-circle elevation-2" alt={user.displayName} />
                </div>
                <div className="info">
                  <Link to="#" className="d-block">
                    {user.displayName}
                  </Link>
                </div>
              </div>
            )}

            <MenuAnt
              mode="vertical"
              defaultSelectedKeys={selectedMenu}
              selectedKeys={selectedMenu}
              onOpenChange={onOpenChange}
              onClick={handleClickMenu}>
              {menu &&
                menu.map((el, i) => {
                  let headName = '';
                  switch (el.head) {
                    case 'StockNonSerial':
                      headName = 'Stock Non Serial';
                      break;
                    case 'BaanChiang':
                      headName = 'Interface บ้านเชียง';
                      break;
                    case 'DownPayment':
                      headName = 'ออมดาวน์';
                      break;
                    case 'SecondHand':
                      headName = 'รับซื้อสินค้ามือสอง';
                      break;
                    default:
                      headName = el.head;
                      break;
                  }
                  return (
                    <MenuAnt.SubMenu title={headName} key={`head${i}`}>
                      {el.menu.map((val, k) => {
                        let icon = '';
                        switch (val.menu_code) {
                          case 'M1001':
                          case 'M1002':
                            icon = <i className="fas fa-money-check nav-icon" />;
                            break;
                          case 'M1003':
                            icon = <i className="fas fa-money-bill-wave nav-icon" />;
                            break;
                          case 'M1004':
                            icon = <i className="fas fa-qrcode nav-icon" />;
                            break;
                          case 'M1005':
                            icon = <i className="fas fa-user nav-icon" />;
                            break;
                          case 'M1006':
                            icon = <i className="fas fa-user-plus nav-icon" />;
                            break;
                          case 'M1007':
                            icon = <i className="fas fa-bell nav-icon" />;
                            break;
                          case 'M1008':
                            icon = <i className="fas fa-phone nav-icon" />;
                            break;
                          case 'M2001':
                          case 'M1010':
                          case 'M6001':
                          case 'M6003':
                          case 'M11006':
                            icon = <i className="fas fa-file-signature nav-icon" />;
                            break;
                          case 'M2002':
                            icon = <i className="fas fa-mobile-alt nav-icon" />;
                            break;
                          case 'M2003':
                            icon = <i className="fas fa-barcode nav-icon" />;
                            break;
                          case 'M2004':
                            icon = <i className="fas fa-file-import nav-icon" />;
                            break;
                          case 'M3001':
                            icon = <i className="fas fa-file-signature nav-icon" />;
                            break;
                          case 'M3002':
                            icon = <i className="fas fa-cog" />;
                            break;
                          case 'M4001':
                          case 'M4007':
                          case 'M6002':
                          case 'M8001':
                          case 'M11002':
                            icon = <i className="fas fa-plus" />;
                            break;
                          case 'M1012':
                          case 'M1013':
                            icon = <i className="fas fa-unlock nav-icon" />;
                            break;
                          case 'M1011':
                            icon = <i className="fas fa-calendar" />;
                            break;
                          case 'M1014':
                            icon = <i className="fas fa-user-times" />;
                            break;
                          case 'M6004':
                          case 'M1014':
                          case 'M8002':
                          case 'M9005':
                          case 'M9011':
                            icon = <i className="fas fa-exchange-alt" />;
                            break;
                          case 'M1019':
                          case 'M6005':
                          case 'M6006':
                          case 'M6008':
                          case 'M6009':
                          case 'M6011':
                          case 'M6012':
                          case 'M6013':
                          case 'M8004':
                          case 'M8005':
                          case 'M8006':
                          case 'M9006':
                          case 'M5003':
                          case 'M5004':
                          case 'M5005':
                          case 'M10001':
                          case 'M10002':
                          case 'M11001':
                            icon = <i className="fas fa-book" />;
                            break;
                          case 'M10003':
                            icon = <i className="fas fa-ban" />;
                            break;
                          case 'M7001':
                            icon = <i className="fas fa-light fa-box-open" />;
                            break;
                          case 'M9001':
                            icon = <i className="fas fa-user" />;
                            break;
                          case 'M9002':
                            icon = <i className="fas fa-user-tie" />;
                            break;
                          case 'M9003':
                            icon = <i className="fas fa-box-open" />;
                            break;
                          case 'M9004':
                          case 'M9007':
                          case 'M9010':
                            icon = <i className="fas fa-file-invoice" />;
                            break;
                          case 'M9007':
                            icon = <i className="fas fa-list" />;
                            break;
                          case 'M9008':
                            icon = <i className="fas fa-money-bill" />;
                            break;
                          case 'M11005':
                            icon = <i className="fas fa-pen" />;
                            break;
                          default:
                            icon = <i className="fas fa-cog" />;
                            break;
                        }
                        // if(val.menu_name.includes("รายงาน")){
                        //     menu.push(val)
                        // }else{
                        return (
                          <>
                            <MenuAnt.Item
                              key={`sub${i}${k}`}
                              onClick={() => {
                                // if(val.path.includes('/v2')) {
                                //   window.location.assign(val.path) // For Adminv2 Web
                                // } else {
                                //   history.push(val.path);
                                // }
                                history.push(val.path);
                              }}
                              icon={icon}>
                              {val.menu_name}
                            </MenuAnt.Item>
                          </>
                        );
                        //}
                      })}
                    </MenuAnt.SubMenu>
                  );
                })}
            </MenuAnt>
          </div>
        </AsideResponsive>
      ) : (
        <div style={{ backgroundColor: '#f4f6f9' }}></div>
      )}
    </div>
  );
};

export default Menu;
